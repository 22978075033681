import React, { useState } from 'react'
import Img from 'gatsby-image'
import ModalVideo from 'react-modal-video'
import tw, { styled } from 'twin.macro'

const VideoWrapper = styled.div``

const Video = ({ video }) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <VideoWrapper tw="py-8">
      <div
        tw="hidden md:block max-w-screen-md cursor-pointer rounded-lg"
        onClick={() => setOpen(true)}>
        <Img
          fluid={video.placeholder_image.fluid}
          objectFit="contain"
          tw="rounded-lg"
        />
      </div>
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId={video.videoid}
        youtube={{
          autoplay: 1,
          mute: 1,
        }}
        onClose={() => setOpen(false)}
      />
    </VideoWrapper>
  )
}
export default Video
