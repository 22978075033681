import React from 'react'
import tw, { styled } from 'twin.macro'
import { Tweet } from 'react-twitter-widgets'

const TweetElementWrapper = styled.div`
  .twitter-tweet {
    margin: auto;
  }
`

const TweetElement = ({ tweet }) => {
  const index = tweet.tweet.url.lastIndexOf('/')
  const tweetId = tweet.tweet.url.substring(index + 1, tweet.tweet.url.length)

  return (
    <TweetElementWrapper tw="py-8">
      <Tweet tweetId={tweetId} />
    </TweetElementWrapper>
  )
}
export default TweetElement
