import React from 'react'
import tw, { styled } from 'twin.macro'

const LinkedinWrapper = styled.div`
  iframe {
    margin: auto;
  }
`

const Linkedin = ({ linkedin }) => {
  return (
    <LinkedinWrapper tw="py-8">
      <iframe
        src={linkedin.link1.url}
        height="470"
        width="504"
        frameborder="0"
        allowfullscreen=""
        title="Embedded post"></iframe>
    </LinkedinWrapper>
  )
}
export default Linkedin
