import React from 'react'
import tw, { styled } from 'twin.macro'
import CommentIcon from '../../images/comment-icon.svg'

const CommentWrapper = styled.div`
  @media (max-width: 640px) {
    margin-left: -25px;
    margin-right: -25px;
    font-size: 20px;
  }
`

const Comment = ({ comment }) => {
  return (
    <div tw="pt-8 pb-8">
      <CommentWrapper tw="py-7 md:py-4 px-7 bg-air rounded-lg relative">
        <img src={CommentIcon} tw="absolute -top-5 left-4" />
        <div tw="bg-white rounded-lg py-12 px-10 md:py-5 md:px-16">
          <div
            dangerouslySetInnerHTML={{
              __html: comment.comment.html,
            }}
            tw="text-xl text-navy"></div>
        </div>
      </CommentWrapper>
    </div>
  )
}
export default Comment
