import React from 'react'
import tw, { styled } from 'twin.macro'
import { Link } from 'gatsby'
import QuoteIcon from '../../images/quote-icon.svg'

const ReadNowWrapper = styled.div`
  @media (min-width: 769px) {
    .feature-image {
      width: 250px;
      height: 250px;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }
  @media (max-width: 768px) {
    .feature-image {
      height: 207px;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }
`

const ReadNow = ({ read }) => {
  return (
    <div tw="py-8">
      <ReadNowWrapper tw="py-12 border-t border-b border-turquoise">
        <div tw="md:flex rounded-lg items-center">
          <div
            tw="md:flex-none rounded-t-lg md:rounded-lg md:rounded-tr-none"
            className="feature-image"
            style={{
              backgroundImage: `url(${read.image1.fluid.src})`,
            }}></div>
          <div
            tw="pl-0 md:pl-16 pt-8 md:pt-0 md:flex-grow rounded-b-lg md:rounded-r-lg"
            className="shadow-rounded">
            <div tw="flex flex-col gap-4 text-center">
              <div
                dangerouslySetInnerHTML={{
                  __html: read.description.html,
                }}
                tw="max-w-md text-ocean text-left text-lg"></div>

              <div tw="text-left flex flex-col md:flex-row gap-y-4 mt-4 cursor-pointer">
                <Link to={read.link.url} target="_blank">
                  <span tw="text-ocean font-semibold text-lg mr-4">
                    {read.button}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </ReadNowWrapper>
    </div>
  )
}
export default ReadNow
