import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import tw, { styled } from 'twin.macro'
import { LocaleContext } from '../components/layout'
import { useForm } from 'react-hook-form'
import Check from '../images/Check.svg'
import axios from 'axios'
import Send from '../images/Send.svg'
import { SubForm, SmallSolidTurquoiseInput } from '../utils/styles'
import Twitter from '../images/Twitter.svg'
import Facebook from '../images/facebook.svg'
import LinkedIn from '../images/LinkedIn.svg'
import Slider from 'react-slick'
import { SmallSolidTealButton } from '../utils/styles'
import SEO from '../components/SEO/SEO'
import ProgressBar from 'react-scroll-progress-bar'
import Quote from '../components/resource/quote'
import LocalizedLink from '../components/localized-link'
import Comment from '../components/resource/comment'
import DownloadEbook from '../components/resource/download-ebook'
import TweetElement from '../components/resource/tweet'
import Text from '../components/resource/text'
import Video from '../components/resource/video'
import ImageElement from '../components/resource/image-element'
import DownloadTemplate from '../components/resource/download-template'
import WatchVideo from '../components/resource/watch-video'
import Contact from '../components/resource/contact'
import ReadNow from '../components/resource/read-now'
import Spotify from '../components/resource/spotify'
import Linkedin from '../components/resource/linkedin'
import IframeElement from '../components/resource/iframe-element'
import { withPreview } from 'gatsby-source-prismic'
import ModalVideo from 'react-modal-video'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// styles
const ResourceWrapper = styled.div`
  .resource-content {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: #263c5c;
      padding: 0 0 1rem 0;
    }
    p {
      padding-bottom: 1rem;
      font-size: 18px;
      .check-list {
        color: #263c5c;
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }
      .check-list::before {
        content: '';
        display: block;

        margin-right: 12px;
        background-size: cover;
        background-image: url(${Check});
        width: 16px;
        height: 16px;
        padding: 0 9px;
      }
    }
    ul {
      margin-left: 1rem;
      padding-bottom: 1rem;
      li {
        list-style: disc;
      }
    }
  }
  .popular-slider {
    margin: 30px -25px 0 -25px;
    padding-bottom: 35px;
  }
  .popular-dots {
    bottom: -15px;
  }
`
// markup
const ResourcePage = ({
  data: { prismicResource },
  pageContext: { locale, resourceList, featuredResources },
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const localeResourceList = resourceList.filter((r) => r.lang === locale)
  const featuredLocaleResourceList = localeResourceList.filter(
    (r) =>
      typeof featuredResources.find( r => r.lang === lang.locale).data.most_popular_resources.find(
        (p) => p.most_popular.uid === r.uid
      ) != 'undefined'
  )
  const [isOpen, setOpen] = useState(false)
  const [videoItem, setVideoItem] = useState(null)
  const [subscribed, setSubscribed] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const { register, handleSubmit } = useForm()
  const onSubmit = async (data) => {
    setSubmitting(true)
    const response = await axios.post('/.netlify/functions/subscribe', {
      form_id: '108139882',
      email: data.email,
    })
    if (response.data.hasOwnProperty('id')) {
      setSubscribed(true)
    }
    setSubmitting(false)
  }
  const settings = {
    dots: true,
    arrows: false,
    dotsClass: 'slick-dots slick-thumb popular-dots',
    infinite: false,
    speed: 500,
    centerMode: false,
    slidesToShow: 1.1,
    slidesToScroll: 1,
  }
  return (
    <main>
      <div className="progress-bar">
        <ProgressBar />
      </div>
      <SEO
        title={prismicResource.data.meta_title}
        desc={prismicResource.data.meta_description}
        locale={locale}
      />
      <ResourceWrapper tw="container">
        <div tw="md:grid md:grid-cols-3 gap-16 pt-32 md:pt-14 pb-16">
          <div tw="col-span-2">
            <div>
              <Img fluid={prismicResource.data.image.fluid} tw="rounded-lg" />
            </div>
            <h1 tw="text-navy mt-12 md:mt-24">
              {prismicResource.data.meta_title}
            </h1>
            {prismicResource.data.content.html !== '' ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: prismicResource.data.content.html,
                }}
                tw="text-ocean mt-5 pb-10"
                className="resource-content"></div>
            ) : false}

            {prismicResource.data.body.map((b) => {
              switch (b.slice_type) {
                case 'text':
                  return <Text text={b.primary} />
                case 'quote_element':
                  return <Quote quote={b.primary} />
                case 'download_ebook_element':
                  return <DownloadEbook ebook={b.primary} locale={locale} />
                case 'contact_element':
                  return <Contact contact={b.primary} />
                case 'read_now_element':
                  return <ReadNow read={b.primary} />
                case 'twitter_element':
                  return <TweetElement tweet={b.primary} />
                case 'download_template_element':
                  return <DownloadTemplate template={b.primary} />
                case 'comment_element':
                  return <Comment comment={b.primary} />
                case 'watch_video_element':
                  return <WatchVideo feature={b.primary} />
                case 'video_element':
                  return <Video video={b.primary} />
                case 'image_section':
                  return <ImageElement image={b.primary} />
                case 'spotify':
                  return <Spotify spotify={b.primary} />
                case 'linkedin_element':
                  return <Linkedin linkedin={b.primary} />
                case 'iframe_element':
                  return <IframeElement iframe={b.primary} />
                default:
                  return null
              }
            })}
            <div tw="flex flex-row mt-8 gap-12">
              <div tw="text-teal text-sm tracking-widest uppercase flex items-center">
                Share
              </div>
              <div tw="flex flex-row gap-12 items-center">
                <div>
                  <a
                    href={`https://twitter.com/intent/tweet/?text=${prismicResource.data.meta_title}&url=${process.env.GATSBY_DOMAIN}/resource/${prismicResource.uid}%2F&via=Quantics_io`}>
                    <span>
                      <img src={Twitter} tw="w-6" />
                    </span>
                  </a>
                </div>
                <div>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.GATSBY_DOMAIN}/resource/${prismicResource.uid}`}
                    target="_blank">
                    <span>
                      <img src={Facebook} tw="w-6" />
                    </span>
                  </a>
                </div>
                <div>
                  <a
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${process.env.GATSBY_DOMAIN}/resource/${prismicResource.uid}&title=${prismicResource.data.meta_title}&source=${prismicResource.data.meta_title}`}
                    target="_blank">
                    <span>
                      <img src={LinkedIn} tw="w-6" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
          <div tw="sticky -top-72">
            <div>
              <div tw="mt-8 md:mt-0 mb-8">
                <span tw="uppercase text-teal text-sm tracking-widest font-bold">
                  Most popular
                </span>
              </div>
              <div tw="hidden md:flex flex-col gap-8">
                {featuredLocaleResourceList.slice(0, 5).map((r, key) => {
                  return (
                    <div key={key} tw="flex gap-5">
                      <div tw="w-24 flex-none">
                        {r.data.category === 'Papers' ||
                        r.data.category === 'News' ||
                        r.data.category === 'Videos' ? (
                          r.data.category === 'Videos' ? (
                            <div
                              onClick={() => {
                                setOpen(true)
                                setVideoItem(r)
                              }}
                              tw="cursor-pointer">
                              {r.data.image.fluid != null ? (
                                <img
                                  src={r.data.image.fluid.src}
                                  tw="rounded-lg"
                                />
                              ) : false}
                            </div>
                          ) : (
                            <Link to={r.data.link.url} target="_blank">
                              {r.data.image.fluid != null ? (
                                <img
                                  src={r.data.image.fluid.src}
                                  tw="rounded-lg"
                                />
                              ) : false}
                            </Link>
                          )
                        ) : (
                          <LocalizedLink to={`/resource/${r.uid}`}>
                            {r.data.image.fluid != null ? (
                              <img
                                src={r.data.image.fluid.src}
                                tw="rounded-lg"
                              />
                            ) : false}
                          </LocalizedLink>
                        )}
                      </div>
                      <div tw="flex-grow">
                        {r.data.category === 'Papers' ||
                        r.data.category === 'News' ||
                        r.data.category === 'Videos' ? (
                          r.data.category === 'Videos' ? (
                            <div
                              onClick={() => {
                                setOpen(true)
                                setVideoItem(r)
                              }}
                              tw="cursor-pointer">
                              {r.data.image.fluid != null ? (
                                <h5 tw="text-navy" className="article-title">
                                  {r.data.meta_title}
                                </h5>
                              ) : false}
                            </div>
                          ) : (
                            <Link to={r.data.link.url} target="_blank">
                              {r.data.image.fluid != null ? (
                                <h5 tw="text-navy" className="article-title">
                                  {r.data.meta_title}
                                </h5>
                              ) : false}
                            </Link>
                          )
                        ) : (
                          <LocalizedLink to={`/resource/${r.uid}`}>
                            {r.data.image.fluid != null ? (
                              <h5 tw="text-navy" className="article-title">
                                {r.data.meta_title}
                              </h5>
                            ) : false}
                          </LocalizedLink>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
              <Slider
                {...settings}
                tw="md:hidden mt-8"
                className="popular-slider">
                {featuredLocaleResourceList.slice(0, 5).map((item, key) => {
                  return (
                    <div key={key} tw="px-2">
                      <div>
                        {item.data.category === 'Papers' ||
                        item.data.category === 'News' ||
                        item.data.category === 'Videos' ? (
                          item.data.category === 'Videos' ? (
                            <div
                              onClick={() => {
                                setOpen(true)
                                setVideoItem(item)
                              }}
                              tw="cursor-pointer">
                              {item.data.image.fluid != null ? (
                                <img
                                  src={item.data.image.fluid.src}
                                  tw="rounded-t-lg"
                                />
                              ) : false}
                            </div>
                          ) : (
                            <Link to={item.data.link.url} target="_blank">
                              {item.data.image.fluid != null ? (
                                <img
                                  src={item.data.image.fluid.src}
                                  tw="rounded-t-lg"
                                />
                              ) : false}
                            </Link>
                          )
                        ) : (
                          <LocalizedLink to={`/resource/${item.uid}`}>
                            {item.data.image.fluid != null ? (
                              <img
                                src={item.data.image.fluid.src}
                                tw="rounded-t-lg"
                              />
                            ) : false}
                          </LocalizedLink>
                        )}
                      </div>
                      <div tw="bg-air py-8 px-8 md:flex-grow" tw="rounded-b-lg">
                        <h3 tw="text-navy">{item.data.meta_title}</h3>
                        {item.data.category === 'Papers' ||
                        item.data.category === 'News' ||
                        item.data.category === 'Videos' ? (
                          item.data.category === 'Videos' ? (
                            <div
                              onClick={() => {
                                setOpen(true)
                                setVideoItem(item)
                              }}
                              tw="cursor-pointer">
                              <SmallSolidTealButton tw="mt-5">
                                Read news
                              </SmallSolidTealButton>
                            </div>
                          ) : (
                            <Link to={item.data.link.url} target="_blank">
                              <SmallSolidTealButton tw="mt-5">
                                Read news
                              </SmallSolidTealButton>
                            </Link>
                          )
                        ) : (
                          <LocalizedLink to={`/resource/${item.uid}`}>
                            <SmallSolidTealButton tw="mt-5">
                              Read news
                            </SmallSolidTealButton>
                          </LocalizedLink>
                        )}
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
            <SubForm tw="mt-20 w-full">
              <form onSubmit={handleSubmit(onSubmit)} tw="flex flex-col">
                <img src={Send} tw="w-6 m-auto" />
                <h4 tw="text-center mt-6">
                  {!subscribed
                    ? i18n.subscribe.headline
                    : i18n.subscribe.message.headline}
                </h4>
                <p tw="text-center mt-4 mb-6">
                  {!subscribed
                    ? i18n.subscribe.desc
                    : i18n.subscribe.message.desc}
                </p>
                {!subscribed ? (
                  <input
                    {...register('email', { required: true })}
                    placeholder={i18n.subscribe.email}
                    className="q-input"
                  />
                ) : false}
                {!subscribed ? (
                  <SmallSolidTurquoiseInput
                    type="submit"
                    tw="relative md:text-lg"
                    className={submitting ? 'button is-loading' : 'button'}
                    disabled={submitting}>
                    {i18n.subscribe.button}
                  </SmallSolidTurquoiseInput>
                ) : false}
              </form>
            </SubForm>
            </div>
          </div>
        </div>
        {videoItem != null &&
        videoItem.data.category === 'Videos' &&
        videoItem.data.link.url ? (
          <div>
            <ModalVideo
              channel="youtube"
              isOpen={isOpen}
              videoId={videoItem.data.link.url.substring(
                videoItem.data.link.url.lastIndexOf('=') + 1,
                videoItem.data.link.url.length
              )}
              youtube={{
                autoplay: 1,
                mute: 1,
              }}
              onClose={() => {
                setOpen(false)
                setVideoItem(null)
              }}
            />
          </div>
        ) : false}
      </ResourceWrapper>
    </main>
  )
}

export default withPreview(ResourcePage)
export const pageQuery = graphql`
  query ResourceByUid($uid: String!, $locale: String!) {
    prismicResource(uid: { eq: $uid }, lang: { eq: $locale }) {
      data {
        meta_title
        meta_description
        category
        image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        content {
          html
        }
        body {
          ... on PrismicResourceBodyText {
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicResourceBodyTwitterElement {
            slice_type
            primary {
              tweet {
                url
              }
            }
          }
          ... on PrismicResourceBodyQuoteElement {
            slice_type
            primary {
              quote
              writer
            }
          }
          ... on PrismicResourceBodyDownloadEbookElement {
            slice_type
            primary {
              button
              description {
                html
              }
              image1 {
                fluid {
                  src
                }
              }
              title {
                text
              }
              link {
                url
              }
            }
          }
          ... on PrismicResourceBodyContactElement {
            slice_type
            primary {
              button
              title {
                text
              }
              description {
                html
              }
            }
          }
          ... on PrismicResourceBodyReadNowElement {
            primary {
              button
              image1 {
                fluid {
                  src
                }
              }
              description {
                html
              }
              link {
                url
              }
            }
            slice_type
          }
          ... on PrismicResourceBodyDownloadTemplateElement {
            slice_type
            primary {
              button
              title {
                text
              }
              image1 {
                fluid {
                  src
                }
              }
              description {
                html
              }
              link {
                url
              }
            }
          }
          ... on PrismicResourceBodyCommentElement {
            slice_type
            primary {
              comment {
                html
              }
            }
          }
          ... on PrismicResourceBodyWatchVideoElement {
            slice_type
            primary {
              button
              description {
                html
              }
              image1 {
                fluid {
                  src
                }
              }
              title {
                text
              }
              videoid
            }
          }
          ... on PrismicResourceBodyVideoElement {
            slice_type
            primary {
              placeholder_image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              videoid
            }
          }
          ... on PrismicResourceBodyImageSection {
            slice_type
            primary {
              image1 {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicResourceBodySpotify {
            slice_type
            primary {
              spotify_link {
                url
              }
            }
          }
          ... on PrismicResourceBodyLinkedinElement {
            slice_type
            primary {
              link1 {
                url
              }
            }
          }
          ... on PrismicResourceBodyIframeElement {
            slice_type
            primary {
              iframe_code
            }
          }
        }
      }
      uid
    }
  }
`
