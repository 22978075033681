import React from 'react'
import tw, { styled } from 'twin.macro'

const IframeElementWrapper = styled.div`
  iframe {
    margin: auto;
  }
`

const IframeElement = ({ iframe }) => {
  return (
    <IframeElementWrapper tw="py-8">
      <div dangerouslySetInnerHTML={{ __html: iframe.iframe_code }} />
    </IframeElementWrapper>
  )
}
export default IframeElement
