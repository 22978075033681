import React from 'react'
import tw, { styled } from 'twin.macro'

const SpotifyWrapper = styled.div``

const Spotify = ({ spotify }) => {
  return (
    <SpotifyWrapper tw="py-8">
      <iframe
        src={spotify.spotify_link.url}
        width="100%"
        height="232"
        frameBorder="0"
        allowtransparency="true"
        allow="encrypted-media"></iframe>
    </SpotifyWrapper>
  )
}
export default Spotify
