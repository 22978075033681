import React from 'react'
import Img from 'gatsby-image'
import tw, { styled } from 'twin.macro'

const ImageElementWrapper = styled.div``

const ImageElement = ({ image }) => {
  return (
    <ImageElementWrapper tw="py-8">
      <Img fluid={image.image1.fluid} objectFit="contain" tw="rounded-lg" />
    </ImageElementWrapper>
  )
}
export default ImageElement
