import React from 'react'
import tw, { styled } from 'twin.macro'
import QuoteIcon from '../../images/quote-icon.svg'

const QuoteWrapper = styled.div`
  @media (max-width: 640px) {
    margin-left: -25px;
    margin-right: -25px;
    font-size: 20px;
  }
`

const Quote = ({ quote }) => {
  return (
    <div tw="py-8">
      <QuoteWrapper tw="py-7 md:py-4 px-7 bg-air rounded-lg relative">
        <img src={QuoteIcon} tw="absolute -top-5 left-4" />
        <div tw="bg-white rounded-lg py-12 px-10 md:py-5 md:px-16 text-xl text-navy">
          <span tw="font-bold">"{quote.quote}",</span>
          <span>{quote.writer}</span>
        </div>
      </QuoteWrapper>
    </div>
  )
}
export default Quote
