import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { SmallSolidTurquoiseInput } from '../../utils/styles'
const DownloadEbookWrapper = styled.div`
  .shadow-rounded {
    box-shadow: 0px 0px 7px rgba(56, 195, 224, 0.1529);
  }
  @media (min-width: 769px) {
    .ebook-image {
      width: 318px;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }
  @media (max-width: 768px) {
    .ebook-image {
      height: 207px;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }
`

const DownloadEbook = ({ ebook, locale }) => {
  const handleMailerLitePopup = () => {
    if (locale === 'en-us') {
      window.ml_account('webforms', '4168069', 'u7f8k1', 'show')
    } else {
      window.ml_account('webforms', '4188439', 'n4d0h1', 'show')
    }
  }
  return (
    <DownloadEbookWrapper tw="py-8">
      <div tw="md:flex rounded-lg">
        <div
          tw="md:flex-none rounded-t-lg md:rounded-l-lg md:rounded-tr-none"
          className="ebook-image"
          style={{
            backgroundImage: `url(${ebook.image1.fluid.src})`,
          }}></div>
        <div
          tw="py-7 px-7 md:flex-grow rounded-b-lg md:rounded-r-lg"
          className="shadow-rounded">
          <div tw="flex flex-col gap-4 text-center">
            <h4 tw="text-navy text-left">{ebook.title.text}</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: ebook.description.html,
              }}
              tw="max-w-md text-ocean text-left text-sm"></div>

            <div tw="flex flex-col md:flex-row gap-y-4 mt-4">
              <Link to={ebook.link.url} target="_blank">
                <SmallSolidTurquoiseInput tw="relative md:text-lg py-2 px-8">
                  {ebook.button}
                </SmallSolidTurquoiseInput>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </DownloadEbookWrapper>
  )
}
export default DownloadEbook
