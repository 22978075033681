import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import BlogPlay from '../../images/blog-play.svg'
import ModalVideo from 'react-modal-video'
const WatchVideoWrapper = styled.div`
  .shadow-rounded {
    box-shadow: 0px 0px 7px rgba(56, 195, 224, 0.1529);
  }
  @media (min-width: 769px) {
    .feature-image {
      width: 318px;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }
  @media (max-width: 768px) {
    .feature-image {
      height: 207px;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }
`

const WatchVideo = ({ feature }) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <WatchVideoWrapper tw="py-8">
      <div tw="md:flex rounded-lg">
        <div
          tw="md:flex-none rounded-t-lg md:rounded-l-lg md:rounded-tr-none"
          className="feature-image"
          style={{
            backgroundImage: `url(${feature.image1.fluid.src})`,
          }}></div>
        <div
          tw="py-7 px-7 md:flex-grow rounded-b-lg md:rounded-r-lg"
          className="shadow-rounded">
          <div tw="flex flex-col gap-4 text-center">
            <h4 tw="text-navy text-left">{feature.title.text}</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: feature.description.html,
              }}
              tw="max-w-md text-ocean text-left text-sm"></div>

            <div
              tw="text-left flex flex-row gap-y-4 mt-4 cursor-pointer"
              onClick={() => setOpen(true)}>
              <span tw="text-ocean font-semibold text-lg mr-4">
                {feature.button}
              </span>
              <img src={BlogPlay} tw="w-6" />
            </div>
          </div>
        </div>
      </div>
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId={feature.videoid}
        youtube={{
          autoplay: 1,
          mute: 1,
        }}
        onClose={() => setOpen(false)}
      />
    </WatchVideoWrapper>
  )
}
export default WatchVideo
