import React from 'react'
import tw, { styled } from 'twin.macro'
import BottomBG from '../../images/partner-bottom-bg.svg'
import LocalizedLink from '../../components/localized-link'
import { SmallSolidTurquoiseInput } from '../../utils/styles'

const BottomWrapper = styled.div`
  background-image: url(${BottomBG});
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 640px) {
    h4 {
      font-size: 30px;
      font-weight: 600;
    }
  }
`

const Contact = ({ contact }) => {
  return (
    <div tw="py-8">
      <BottomWrapper tw="rounded-lg pt-12 px-7 md:px-10 pb-16">
        <div tw="md:grid md:grid-cols-2 gap-10 rounded-lg items-center">
          <div tw="text-white max-w-md flex justify-self-end rounded-t-lg md:rounded-l-lg">
            <div tw="text-center md:text-left">
              <h4>{contact.title.text}</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: contact.description.html,
                }}
                tw="text-lg md:text-sm mt-4"></div>
            </div>
          </div>
          <div tw="md:flex items-center justify-center rounded-b-lg mt-8 md:mt-0 md:rounded-r-lg">
            <LocalizedLink to="/request-meeting">
              <SmallSolidTurquoiseInput tw="relative md:text-lg w-full md:w-auto py-7 md:py-4 md:px-14">
                {contact.button}
              </SmallSolidTurquoiseInput>
            </LocalizedLink>
          </div>
        </div>
      </BottomWrapper>
    </div>
  )
}
export default Contact
